
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/notice/alert/BasicUsage.vue";
import EUITheme from "@/views/resources/documentation/element-ui/notice/alert/Theme.vue";
import EUICustomizableCloseButton from "@/views/resources/documentation/element-ui/notice/alert/CustomizableCloseButton.vue";
import EUIWithIcon from "@/views/resources/documentation/element-ui/notice/alert/WithIcon.vue";
import EUICenteredText from "@/views/resources/documentation/element-ui/notice/alert/CenteredText.vue";
import EUIWithDescription from "@/views/resources/documentation/element-ui/notice/alert/WithDescription.vue";
import EUIWithIconAndDescription from "@/views/resources/documentation/element-ui/notice/alert/WithIconAndDescription.vue";

export default defineComponent({
  name: "alert",
  components: {
    EUIBasicUsage,
    EUITheme,
    EUICustomizableCloseButton,
    EUIWithIcon,
    EUICenteredText,
    EUIWithDescription,
    EUIWithIconAndDescription
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Alert");
    });
  }
});
